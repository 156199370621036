import * as React from 'react';
import { useState, useEffect } from 'react';

//import styles from './RouterComponent.module.scss';

// This component stops the router rendering a div as that messes with the page layout



export interface IRouterComponentProps{
    children?: any;
}

export const RouterComponent = (props: IRouterComponentProps) => {
    const { children } = props;

    return (
        <>
            {
                children
            }
        </>
    )
}