import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Router, RouteComponentProps, useParams } from '@reach/router';
import { Button, ButtonStyles } from '../../components/Button/Button';

import * as styles from './uploader.module.scss';

import * as globalStyles from '../../globals.module.scss';
import { RouterComponent } from '../../components/RouterComponent/RouterComponent';
import { FabricIcon } from '../../components/FabricIcon/FabricIcon';

import * as Sentry from "@sentry/react";

export interface IUploaderProps extends RouteComponentProps{

}

// const UPLOAD_URL_ROOT = `https://pboportalstorage.blob.core.windows.net`;
const UPLOAD_URL_ROOT = `https://static.pbo.vic.gov.au/uploads`;


export interface IUploadProgressItem{
    [fileaname: string]: number; // filename: percentcomplete
}

const Uploader = (props: IUploaderProps) => {
    const { uploadlink } = useParams();
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [mouseInDropzone, setMouseInDropzone] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [container, setContainer] = useState<string>();
    const [uploadProgress, setUploadProgress] = useState<IUploadProgressItem>({});
    const [sasToken, setSasToken] = useState<string>();
    const [message, setMessage] = useState<string>("Upload not started...");
    const [error, setError] = useState<string>(null);

    useEffect(() =>{
        Sentry.init({
            dsn: "https://4a06c3932f7242a08fd4a3f4d43b54b1@o4505952446775296.ingest.sentry.io/4505952447758336",
            integrations: [
              new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/pbo\.vic\.gov\.au/],
              }),
              new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });
    },[]);

    useEffect(() => {
        console.log(uploadlink);
        console.log(window.atob(decodeURIComponent(uploadlink)));
        parseUploadLink(uploadlink);
    }, [uploadlink]);

    useEffect(() => {
        if(uploadProgress){
            const uploadKeys = Object.keys(uploadProgress);
            const notCompleteUploads = uploadKeys.filter(k => uploadProgress[k] < 1);
            console.log(notCompleteUploads, uploadProgress);

            if(uploadKeys.length > 0 && notCompleteUploads.length <= 0){
                onUploadComplete();
            }
            else if(uploadKeys.length > 0){
                setMessage(`Upload in progress, please wait... (${((Object.keys(uploadProgress).reduce((total, curr, i) => total + uploadProgress[curr] ,0) / Object.keys(uploadProgress).length) * 100).toFixed(0)}%)`);
            }
        }
    },[uploadProgress]);

    const onUploadComplete = async () => {
        const notifyUrl = `/api/notifyUpload?containerName=${ container }`;

        const res = await fetch(notifyUrl, {
            method: "GET"
        });

        if(res.ok){
            setMessage("Upload complete!");
        }
        else{
            setError("Uh-oh, an error ocurred processing the upload, please try again.");
        }
    }

    const onUploadClick = async () => {
        setMessage("Upload in progress, please wait...");
        setUploadProgress(selectedFiles.map((file) => {
            return {
                [file.name]: 0
            }
        }).reduce((p,c) => ({ ...p, ...c}), {}));

        await selectedFiles.reduce(async (prev, curr) => {
            await prev;

            return uploadFile(curr);
        }, Promise.resolve());

        // await Promise.all(selectedFiles.map((file) => {
        //     return uploadFile(file);
        // }));
    }

    const parseUploadLink = (link: string) => {
        const decoded = decodeURIComponent(link);
        const plainText = window.atob(decoded);
        // console.log(plainText);
        const [containerName, sasUrl] = plainText.split("||");
        // console.log(sasUrl);
        const sasToken = sasUrl.replace(/https:\/\/[^?]+\?/gi, '');

        // console.log(sasToken);
        setSasToken(sasToken);
        setContainer(containerName);
    }

    const uploadFile = useCallback(async (file: File, startPosition?: number, blockIds?: string[]) => {
        const blockIdArray = blockIds || [];
        const sliceSize = 1000 * 1024;
        const nextSliceStart = (startPosition || 0) + sliceSize + 1;
        const currentBlockId = window.btoa(`blk-${ (blockIdArray.length).toString().padStart(6,"0").slice(0,6) }`);
        blockIdArray.push(currentBlockId);

        const Reader = new FileReader();
        const blob = file.slice(startPosition, nextSliceStart);
        
        Reader.onloadend = (event: ProgressEvent): void => {
            if(Reader.readyState === FileReader.DONE){
                const body = Reader.result;
                const options = {
                    body,
                    headers: {
                        'content-length': blob.size.toString(),
                        'content-type': file.type,
                        'x-ms-blob-content-type': file.type,
                        'x-ms-date': (new Date()).toUTCString(),
                        'x-ms-version':'2018-03-28'
                    },
                    method: 'PUT'
                }

                const uploadUrl = `${UPLOAD_URL_ROOT}/${ container }/${ file.name }?${ sasToken }&comp=block&blockid=${ currentBlockId }`;
                fetch(uploadUrl, options)
                    .then((res) => {
                        
                        // If there's a server side error throw it here
                        if(!res.ok){
                            res.text()
                                .then((text) => {
                                    throw new Error(text);
                                })
                                .catch(() => {
                                    throw new Error("An unspecified error ocurred.");
                                })
                        }

                        if(nextSliceStart < file.size){
                            const percentComplete: number = nextSliceStart / file.size;

                            setUploadProgress((currentProgress: IUploadProgressItem) => {
                                return {
                                    ...currentProgress,
                                    [file.name]: percentComplete
                                };
                            });

                            uploadFile(file, nextSliceStart, blockIdArray);
                        }
                        else{
                            let blockListBody = '<?xml version="1.0" encoding="utf-8"?>';
                            blockListBody += '<BlockList>';
                            blockListBody += blockIdArray.map((v) => {
                                return `<Latest>${ v }</Latest>`;
                            }).join("");
                            blockListBody += '</BlockList>';

                            const blockListOptions = {
                                body: blockListBody,
                                headers: {
                                    'Content-Length': blockListBody.length.toString(),
                                    'Content-Type': file.type,
                                    'x-ms-blob-content-type': file.type,
                                    'x-ms-date': (new Date()).toUTCString(),
                                    'x-ms-version':'2018-03-28'
                                },
                                method: 'PUT'
                            };

                            const blockListUrl = `${UPLOAD_URL_ROOT}/${ container }/${ file.name }?${ sasToken }&comp=blocklist`;

                            fetch(blockListUrl, blockListOptions)
                            .then((r) => {

                                // If there's a server side error throw it here
                                if(!r.ok){
                                    r.text()
                                        .then((text) => {
                                            throw new Error(text);
                                        })
                                        .catch(() => {
                                            throw new Error("An unspecified error ocurred.");
                                        })
                                }

                                setUploadProgress((currentProgress: IUploadProgressItem) => {
                                    return {
                                        ...currentProgress,
                                        [file.name]: 1
                                    };
                                });

                                const uploadIsInProgress = Object.keys(uploadProgress).filter(key => uploadProgress[key] < 1).length > 0;
                            })
                            .catch((e) => {
                                console.log(JSON.stringify(e));
                                throw new Error(e);
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setError("An error occurred attempting to upload files, please try again later or contact the PBO.");
                        throw new Error(err);
                    });
            }
        };

        Reader.readAsArrayBuffer(blob);
    },[container, sasToken]);


    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFiles((currentFiles) => [...currentFiles, ...acceptedFiles]);
    },[]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDragEnter: () => setMouseInDropzone(true),
        onDragLeave: () => setMouseInDropzone(false)
    });

    const removeSelectedFile = (event: React.MouseEvent, fileName: string) => {
        event.stopPropagation();
        event.preventDefault();
        
        setSelectedFiles((f) => f.filter(v => v.name !== fileName));
    }
    
    return (
        <section className={ [globalStyles.sectionGrid, globalStyles.sectionGridAlignCenter ].join(" ")  }>
            <span>Welcome to the Victorian PBO secure upload portal.</span>

            <div { ...getRootProps() } className={ isDragActive ? [styles.uploaderContainer, styles.uploaderContainerActive].join(" ") : styles.uploaderContainer }>
                <input {...getInputProps()} />

                <div className={ error ? [styles.uploaderNotificationBar, styles.uploaderNotificationBarError].join(" ") : (message.match(/upload complete/gi) ? [styles.uploaderNotificationBar, styles.uploaderNotificationBarComplete].join(" ") : styles.uploaderNotificationBar) }>
                    <span>{error || message }</span>
                    <div className={ styles.progressIndicator } style={{ "--progress": `${(Object.keys(uploadProgress).reduce((total, curr, i) => total + uploadProgress[curr] ,0) / Object.keys(uploadProgress).length) * 100}%` } as any } ></div>
                </div>

                {
                    selectedFiles && selectedFiles.length > 0 ? (
                        <ul>
                            {
                                selectedFiles.map((file) => {
                                    const { [file.name]: progress } = uploadProgress;
                                    const progressStyle: any = {
                                        "--progress": `${progress * 100}%` || "10%"
                                    };
                                    return (
                                        <li className={ styles.fileListItem }>
                                            <div className={ styles.fileListItemName }>
                                                <a className={ styles.fileRemoveIcon } onClick={ (e) => removeSelectedFile(e, file.name) }><FabricIcon iconName='SubtractCircle24Regular' /></a>
                                                <span>{ file.name }</span>
                                            </div>
                                            <div className={ styles.progressIndicator } style={ progressStyle }></div>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    ) : (
                        <span>Click or drag to upload files...</span>
                    )
                }
            </div>
            
            <div>
                <Button classes={ ButtonStyles.blueButton } onClick={ () => onUploadClick() } text="Start upload" disabled={ selectedFiles.length <= 0 } />
            </div>

            {/* <div className="text-container">
                <h2>{  }</h2>
                <div dangerouslySetInnerHTML={{ __html: this.props.Content }} />
            </div>

            <div className="uploader-container">
                <Dropzone disabled={ this.state.uploadIsInProgress } onDrop={ this.onDrop } className={ this.state.dropzoneIsActive ? "uploader active-uploader" : "uploader" } onDragEnter={ this.onEnterDropzone } onDragLeave={ this.onLeaveDropzone }>
                    <div>
                        <ul>
                            {
                                (!this.state.files || this.state.files.length <= 0) && (
                                    <span>Drag or click to upload files</span>
                                )
                            }
                            {
                                this.state.files && this.state.files.length > 0 && (
                                    <span>{ this.state.uploadIsInProgress ? "Uploading files, please do not refresh your browser or navigate away from this page until the upload is complete." : "Files to upload:" }</span>
                                )
                            }
                            {
                                this.state.files && this.state.files.length > 0 && this.state.files.map((file, i) => {
                                    return (
                                        <li key={i}>
                                            <ProgressIndicator label={ `${ file.name } (${ (file.size / 1000000).toFixed(2) } MB)` } className="upload-progress-indicator" percentComplete={ this.state.uploadProgress[file.name] || 0 } description={ this.state.uploadProgress[file.name] ? `${ (parseFloat(this.state.uploadProgress[file.name]) * 100).toFixed(1) }%` : null } />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Dropzone>
                <div className="upload-button-container">
                    {
                        this.state.uploadSuccess ? (
                            <p className="upload-success-message">Upload complete!</p>
                        ) : (
                            <span>&nbsp;</span>
                        )
                    }
                    <PrimaryButton text="Upload" className="upload-button" onClick={ this.uploadFiles } disabled={ this.state.uploadIsInProgress }/>
                </div>
            </div> */}
        </section>
    )
}

const UploaderWithRouter = () => {
    return (
        <Router basepath='/uploader' component={ ({children}) => <RouterComponent children={children} /> }>
            <Uploader path=":uploadlink" />
        </Router>
    )
}

export default UploaderWithRouter;